import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


function animateFrom(elem, direction) {

    direction = direction || 1;

    let x = 0;
    let y = direction * 100;

    const fromLeft = elem.closest('.section-media-content')?.classList.contains('section-media-content--desktop-media-first') || elem.classList.contains('from-left')
    const fromRight = elem.closest('.section-media-content')?.classList.contains('section-media-content--desktop-text-first') || elem.classList.contains('from-right')

    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";

    if (
      elem.classList.contains('media')
      || elem.classList.contains('from-left')
      || elem.classList.contains('from-right')
      ) {

        if (fromLeft) {
            x = -100;
            y = 0;
        } else if (fromRight) {
            x = 100;
            y = 0;
        }
    }

    gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
      duration: 0.75,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: 'auto'
    });

  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }


export const init = () => {


    const textElements = gsap.utils.toArray(`
        main .text > *,
        main .media,
        main .button,

        main .content-slideshow-navigation__slide,
        main .card-slider__navigation-buttons,

        main .fui-field,
        main .fui-btn
    `);



    textElements.forEach(elem => {

        hide(elem); // assure that the element is hidden when scrolled into view

        elem.style.opacity = 0;
        elem.style.visibility = 'hidden';
        elem.style.willChange =  'transform, opacity';

        ScrollTrigger.create({
          trigger: elem,
          invalidateOnRefresh: true,
        //   markers: true,
          start: 'top bottom',
          onEnter: function() { animateFrom(elem) },
          onEnterBack: function() { animateFrom(elem, -1) },
          onLeave: function() { hide(elem) }, // assure that the element is hidden when scrolled into view
          onLeaveBack: function() { hide(elem) } // assure that the element is hidden when scrolled into view
        });
      });

}

export default init;
