<template>

    <swiper
        class="stage-slider"
        :modules="modules"
    >
        <slot />

        <template #container-end>
            <slot name="pagination" />
        </template>
    </swiper>

</template>

<script>
import {  Autoplay, Pagination } from 'swiper';


export default {

    name: 'StageSlider',

    data: () => {
        return {
            modules: [ Autoplay, Pagination ],
            swiper: null,
        };
    },

}
</script>
