<template>

    <video v-bind="$attrs" :controls="videoControls" :autoplay="videoAutoplay" ref="video">
        <source :src="src" type="video/mp4">
    </video>

    <div v-if="playButtonVisible" class="media__play-icon" @click="play">
        <slot name="icon"></slot>
    </div>

</template>

<script>

export default {

    props: {
        src: {
            type: String,
            required: true
        },
        controls: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            started: false
        }
    },

    computed: {

        videoControls() {
            return this.started && this.controls;
        },

        videoAutoplay() {
            return this.$attrs.hasOwnProperty('autoplay');
        },

        playButtonVisible() {
            return !this.started && !this.videoAutoplay;
        }
    },

    methods: {

        play() {
            this.$refs.video.play();
            this.started = true;
        }
    }

}
</script>
