<template>
    <swiper
        :class="[
            'content-slideshow-navigation',
            'section-content-slideshow__navigation-slider',
            navSliderClasses,
        ]"
        :modules="swiperModulesNavigationSlider"
        :slides-per-view="'auto'"
        :threshold="10"
        :preventClicks="false"
        @swiper="setNavigationSlider"
    >
        <swiper-slide
            class="content-slideshow-navigation__slide"
            v-for="(comp, index) in navigationElements"
            :key="index"
        >
            <component :is="comp" />
        </swiper-slide>
    </swiper>

    <swiper
        class="content-slideshow section-content-slideshow__slider"
        :modules="swiperModulesContentSlider"
        :slides-per-view="1"
        :space-between="68"
        :auto-height="false"
        :centered-slides="true"
        :center-insufficient-slides="false"
        :thumbs="{ swiper: navigationSlider }"
        @slideNextTransitionStart="handleSlideNext"
        @slidePrevTransitionStart="handleSlidePrev"
        @slideChange="pauseVideo"
    >
        <slot />
    </swiper>
</template>

<script>
import { ref } from "vue";
import { FreeMode, Thumbs, Autoplay } from "swiper";

export default {
    name: "ContentSlideshow",

    data: () => {
        return {
            swiperModulesNavigationSlider: [FreeMode],
            swiperModulesContentSlider: [Thumbs, Autoplay],
        };
    },

    setup() {
        const navigationSlider = ref(null);
        const slideDirection = ref("next");

        const setNavigationSlider = (swiper) =>
            (navigationSlider.value = swiper);

        const handleSlideNext = (swiper) => (slideDirection.value = "next");
        const handleSlidePrev = (swiper) => (slideDirection.value = "prev");

        return {
            navigationSlider,
            slideDirection,
            setNavigationSlider,
            handleSlideNext,
            handleSlidePrev,
        };
    },

    computed: {
        navigationElements() {
            return (
                this.$slots["navigation-elements"] &&
                this.$slots["navigation-elements"]()
            );
        },

        navSliderClasses() {
            return {
                [`direction-${this.slideDirection}`]: this.slideDirection,
            };
        },
    },

    methods: {
        pauseVideo(swiper) {
            const prevSlide = swiper.slides[swiper.previousIndex];
            const videos = prevSlide.querySelectorAll("video");

            if (videos.length > 0) {
                videos.forEach((video) => video.pause());
            }

            const iframes = prevSlide.querySelectorAll("iframe");
            if (iframes.length > 0) {
                iframes.forEach((iframe) => {
                    /* prettier-ignore */
                    iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*")
                });
            }
        },
    },
};
</script>
