<script setup>
import { ref } from 'vue';
import { Controller, Parallax, EffectCreative, Navigation } from 'swiper';
import { Swiper } from 'swiper/vue';

const swiper = ref();
// const updateSwiper = () => swiper.value?.update()

const controlledSwiper = ref();
// const updateControlledSwiper = () => controlledSwiper.value?.update();

</script>

<template>
    <div class="text-media-slideshow__image-slider">
        <Swiper
            :modules="[EffectCreative]"
            :allowTouchMove="false"
            effect="creative"
            :creativeEffect="{
                prev: {
                    // will set `translateZ(-400px)` on previous slides
                    translate: [0, 0, -500],
                    opacity: 0,
                },
                next: {
                    // will set `translateX(100%)` on next slides
                    translate: ['70%', 0, -200],
                    scale: 0.8,
                    opacity: .3,
                },
            }"
            @swiper="(swiper) => (controlledSwiper = swiper)"
        >
            <slot name="images" />
        </Swiper>
    </div>

    <div class="text-media-slideshow__text-slider">
        <Swiper
            :modules="[Navigation, Controller]"
            :controller="{
                control: controlledSwiper,
                by: 'slide'
            }"
            centeredSlides
            grabCursor
            slideToClickedSlide
            autoHeight
            direction="horizontal"
            @swiper="(newSwiper) => (swiper = newSwiper)"
            :navigation="{
                prevEl: '.text-media-slideshow__button-prev',
                nextEl: '.text-media-slideshow__button-next',
            }"
        >
            <slot name="texts" />

            <template #container-end>
                <slot name="navigation" />
            </template>
        </Swiper>
    </div>
</template>
