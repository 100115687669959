import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {

    const cardSections = gsap.utils.toArray(".section-cards");

    cardSections.forEach(function(cardSection){

        ScrollTrigger.batch(cardSection.querySelectorAll(".card"), {
            invalidateOnRefresh: true,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: batch => gsap.fromTo(batch,
                { autoAlpha: 0, y: '300px'},
                { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true, ease: "expo", duration: 1.25}
            ),
        });

    });
}

export default init;
