import "./scss/global.scss";
import 'swiper/css';
import { ExpandTransition, FadeTransition } from '@bam/transitions';
import { createApp } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Parallax } from 'swiper';
import { gsap } from "gsap";

window.gsap = gsap;

import * as ANIMATIONS from './animations';

const app = createApp({
    data: () => {
        return {
            navigationOpen: false,
            globalSwiperModules: [
                Navigation,
                Pagination,
                Parallax,
            ],
        };
    },

    mounted() {
        // ANIMATIONS.initMenus();
        // ANIMATIONS.initHeader();
        // ANIMATIONS.initStickySocial();
        ANIMATIONS.initPinnedMedia();
        ANIMATIONS.initCards();
        // ANIMATIONS.initIconList();
        // ANIMATIONS.initTextMedia();

        ANIMATIONS.initElementMovement();
        // ANIMATIONS.initFuiContainer();
    },

    methods: {
        toggleNavigation() {
            this.navigationOpen = !this.navigationOpen;
        },
    },
});

const bps = JSON.parse(getComputedStyle(document.body).getPropertyValue('--bam-breakpoints'));
const breakpoints = useBreakpoints(bps);
app.config.globalProperties.$breakpoints = breakpoints;

const components = import.meta.globEager('./components/**/*.vue')
Object.entries(components).forEach(([ path, definition ]) => {
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')
    app.component(componentName, definition.default)
});

app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);
app.component('expand-transition', ExpandTransition);

app.mount('#app');



document.addEventListener('DOMContentLoaded', (event) => {

    const forms = Array.from(document.querySelectorAll('.section-form__form form'));

    forms.forEach($form => {

        // Find the CSRF token hidden input, so we can replace it
        let $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
        const fuiFormData = JSON.parse($form.dataset['fuiForm']);
        const formHandle = fuiFormData.formHandle;

        fetch('/actions/formie/forms/refresh-tokens?form=' + formHandle)
            .then(result => { return result.json(); })
            .then(result => {
                $csrfInput.outerHTML = result.csrf.input;

                // Find the JavaScript captcha hidden input, so we can update it
                if (result.captchas && result.captchas.javascript) {
                    // JavaScript captcha
                    let jsCaptcha = result.captchas.javascript;

                    const input = $form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]');

                    if (input && jsCaptcha) {
                        input.value = jsCaptcha.value;
                    }

                }

                // Find the Duplicate captcha hidden input, so we can update it
                if (result.captchas && result.captchas.duplicate) {
                    // Duplicate captcha
                    let duplicateCaptcha = result.captchas.duplicate;

                    const input = $form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]');

                    if (duplicateCaptcha && input) {
                        input.value = duplicateCaptcha.value;
                    }
                }

                // Update the form's hash (if using Formie's themed JS)
                if ($form.form && $form.form.formTheme) {
                    $form.form.formTheme.updateFormHash();
                }

            });

    });


});
